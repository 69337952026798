import { RouterProvider, RouterProviderProps } from 'react-router-dom';
import { boxConfig, AuthProvider } from '@cg/frontend-core';
import { PostHogProvider } from '../analytics';
import { ThemeProvider } from '../theme';
import RollbarProvider from '../errors/rollbar';

type Props = {
  router: RouterProviderProps;
  rollbarToken?: string;
};

export default function AppProvider({ router, rollbarToken }: Props) {
  const content = (
    <ThemeProvider>
      <RouterProvider {...router} />
    </ThemeProvider>
  );

  return (
    <AuthProvider>
      <PostHogProvider>
        {boxConfig.isLocal || !rollbarToken ? (
          content
        ) : (
          <RollbarProvider token={rollbarToken}>{content}</RollbarProvider>
        )}
      </PostHogProvider>
    </AuthProvider>
  );
}
