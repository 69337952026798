/* eslint-disable */


import { useHttp } from '@cg/frontend-core';

import { getHostGuestsAggregates, GetHostGuestsAggregatesArgs, getHostMoneyEarnedAggregates, GetHostMoneyEarnedAggregatesArgs, getHostTicketsSoldAggregates, GetHostTicketsSoldAggregatesArgs } from './PlaygroundHostsAggregates.client';

export const getHostGuestsAggregatesHook = (immediateCall: boolean = true, initArgs?: GetHostGuestsAggregatesArgs) => {
  return useHttp(getHostGuestsAggregates, { immediateCall, initArgs })
};
export const getHostMoneyEarnedAggregatesHook = (immediateCall: boolean = true, initArgs?: GetHostMoneyEarnedAggregatesArgs) => {
  return useHttp(getHostMoneyEarnedAggregates, { immediateCall, initArgs })
};
export const getHostTicketsSoldAggregatesHook = (immediateCall: boolean = true, initArgs?: GetHostTicketsSoldAggregatesArgs) => {
  return useHttp(getHostTicketsSoldAggregates, { immediateCall, initArgs })
};
