import { useAuth } from '@cg/frontend-core';
import {
  Dropdown,
  DropdownItem,
  Loader,
  UserAvatar,
} from '@cg/module-frontend/src/components';
import React from 'react';
import {
  listHostsHook,
  switchHostHook,
} from '~/generated/clients/playground/hosts/PlaygroundHosts.hooks';
import { Host } from '~/generated/models/Host';

export const HostSwitch = React.memo(function HostSwitch() {
  const { self, fetchingSelf } = useAuth();
  const { data: hosts, calling: fetchingHosts } = listHostsHook();
  const { call: switchHost } = switchHostHook(false);

  const onSwitchHost = async (host: Host) => {
    const response = await switchHost({
      ids: {
        hostId: host.id,
      },
    });

    if (response.succeeded) {
      window.location.href = '/dashboard';
    }
  };

  return (
    <Loader loading={fetchingSelf || fetchingHosts}>
      <Dropdown
        label={
          <div className="flex flex-row items-center justify-between gap-x-2 my-2">
            <UserAvatar host={self?.host} iconSize="sm" imageSize="sm" />
            {self?.host?.friendlyName}
          </div>
        }
        arrowIcon={false}
        color="secondary"
        dismissOnClick
      >
        <div className="max-h-96 overflow-y-auto">
          {hosts?.result?.map((host) => {
            const isActive = host.uniqueName === self?.host?.uniqueName;
            return (
              <DropdownItem
                key={host.id.getValue()}
                onClick={() => onSwitchHost(host)}
              >
                <div className="flex flex-row items-center justify-between gap-x-2 my-2">
                  <UserAvatar host={host} iconSize="sm" imageSize="sm" />
                  {isActive ? (
                    <span className="font-semibold">{host.friendlyName}</span>
                  ) : (
                    <span>{host.friendlyName}</span>
                  )}
                </div>
              </DropdownItem>
            );
          })}
          {/* <Dropdown.Divider /> */}
          {/* <Dropdown.Item>Create new Host</Dropdown.Item> */}
        </div>
      </Dropdown>
    </Loader>
  );
});
