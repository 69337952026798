import { Loader } from '@cg/module-frontend/src/components';
import { useAuth } from '@cg/frontend-core';
import React, { useEffect } from 'react';
import { boxConfig } from '@cg/module-frontend/src/config';

export default function BecomeAHost() {
  const { self, fetchingSelf, isAuthenticated, login } = useAuth();

  useEffect(() => {
    if (fetchingSelf) {
      return;
    }

    if (!isAuthenticated || !self?.host) {
      login();
    } else {
      window.location.href = `${boxConfig.baseUrls.playGround}/dashboard`;
    }
  }, [self, fetchingSelf]);

  return <Loader horizontal loading />;
}
