/* eslint-disable */


import { useHttp } from '@cg/frontend-core';

import { getHostGuestsGraph, GetHostGuestsGraphArgs, getHostMoneyEarnedGraph, GetHostMoneyEarnedGraphArgs, getHostTicketsSoldGraph, GetHostTicketsSoldGraphArgs } from './PlaygroundHostsGraphs.client';

export const getHostGuestsGraphHook = (immediateCall: boolean = true, initArgs?: GetHostGuestsGraphArgs) => {
  return useHttp(getHostGuestsGraph, { immediateCall, initArgs })
};
export const getHostMoneyEarnedGraphHook = (immediateCall: boolean = true, initArgs?: GetHostMoneyEarnedGraphArgs) => {
  return useHttp(getHostMoneyEarnedGraph, { immediateCall, initArgs })
};
export const getHostTicketsSoldGraphHook = (immediateCall: boolean = true, initArgs?: GetHostTicketsSoldGraphArgs) => {
  return useHttp(getHostTicketsSoldGraph, { immediateCall, initArgs })
};
