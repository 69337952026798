import {
  HelperText,
  Label,
  Modal,
  Spinner,
  FBTabs,
  Textarea,
  TextInput,
  Button,
} from '@cg/module-frontend/src/components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '@cg/frontend-core';
import { SubmitHandler, useForm } from '@cg/module-frontend/src/hooks';
import { featureFlagConstants } from '@cg/common/src/constants';
import { createExperienceHook } from '~/generated/clients/playground/hosts/experiences/PlaygroundHostsExperiences.hooks.ts';
import { CreateExperienceRequest } from '~/generated/models/CreateExperienceRequest.ts';

const FUNNY_STATES = [
  'practicing handshakes...',
  'preparing cocktails...',
  'rehearsing anecdotes...',
  'compiling compliments...',
  'defrosting icebreakers...',
  'ordering name tags...',
  'perfecting dress code...',
  'pre-chilling friendsip...',
];

type CreateExperienceProps = {
  onClose: () => void;
};

export default function CreateExperience({ onClose }: CreateExperienceProps) {
  const navigate = useNavigate();
  const [isManual, setIsManual] = useState(true);
  const { self, hasFlag } = useAuth();
  const hasAIFlag = hasFlag(featureFlagConstants.INTENT_AI);
  const [intentCount, setIntentCount] = useState(0);
  const [includeCount, setIncludeCount] = useState(0);
  const [bringCount, setExcludeCount] = useState(0);
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);
  const [quote, setQuote] = useState<string>('');

  useEffect(() => {
    setIsManual(!hasAIFlag);
  }, [hasAIFlag]);

  const {
    data: experience,
    calling: creatingExperience,
    call: createExperience,
    error,
  } = createExperienceHook(false);

  const onSubmit: SubmitHandler<CreateExperienceRequest> = async (
    payload: CreateExperienceRequest,
  ) => {
    const body = { ...payload };
    if (!self?.host) {
      return;
    }

    if (isManual) {
      delete body.intent;
    } else {
      delete body.title;
    }

    await createExperience({
      ids: {
        hostId: self.host?.id,
      },
      body,
    });
  };

  useEffect(() => {
    if (creatingExperience) {
      setQuote(FUNNY_STATES[Math.floor(Math.random() * FUNNY_STATES.length)]);

      intervalRef.current = setInterval(() => {
        setQuote(FUNNY_STATES[Math.floor(Math.random() * FUNNY_STATES.length)]);
      }, 2750);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [creatingExperience]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm<Partial<CreateExperienceRequest>>({
    mode: 'onBlur',
    defaultValues: {
      title: '',
      intent: {
        intent: '',
        include: '',
        bring: '',
      },
    },
  });

  useEffect(() => {
    if (experience) {
      navigate(`/dashboard/experiences/${experience.id.getValue()}/edit`);
    }
  }, [experience]);

  const getManual = () => {
    return (
      <div className="space-y-6 min-w-72 h-fit">
        <p>
          Create your Experience from scratch. Give your Experience a catchy and
          fun title to get started.
        </p>
        <TextInput
          color="primary"
          id="title"
          type="text"
          placeholder="Experience Name"
          helperText={
            errors.title?.message ? (
              <span className="text-failure ml-3">{errors.title?.message}</span>
            ) : null
          }
          {...register('title', {
            validate: (title: string | undefined) => {
              if (!isManual) {
                return true;
              }

              if ((title?.length ?? 0) < 10) {
                return 'Title must be at least 10 characters';
              }

              if ((title?.length ?? 0) > 64) {
                return 'Title must be less than 64 characters';
              }

              return true;
            },
          })}
        />
        <div className="p-4 rounded border border-dashed border-primary">
          <span className="font-bold text-primary">Tip:</span> The most popular
          experiences have fun-sounding names. If you&apos;re planning a
          &quot;Paint by Numbers Social&quot; then call it something playful
          like &quot;Paint with Pals&quot; or &quot;Canvas & Convos&quot;
          instead.
        </div>
      </div>
    );
  };

  const getAI = () => {
    return (
      <div className="space-y-6 min-w-72 h-fit">
        <p>
          Instant Experience will auto-generate your Experience page for you...
          instantly (we&apos;re really creative). Just tell us a little about
          what you want to host and we&apos;ll do the rest.
        </p>
        <div>
          <Label htmlFor="intent" required>
            What are you hosting?
          </Label>
          <HelperText className="mb-2">
            Tell us a few sentences about the Experience: what it is, who
            it&apos;s for, how many people.
          </HelperText>
          <div className="relative">
            <Textarea
              color="primary"
              id="intent"
              rows={3}
              placeholder="I'm hosting a social yoga flow in the park for up to 20 people, followed by a free picnic. Open to all levels!"
              helperText={
                errors.intent?.intent?.message ? (
                  <span className="text-failure ml-3">
                    {errors.intent?.intent?.message}
                  </span>
                ) : null
              }
              {...register('intent.intent', {
                onChange: (e) => setIntentCount(e.target.value.length),
                validate: (intent: string) => {
                  if (isManual) {
                    return true;
                  }

                  if (intent?.length < 50) {
                    return 'Intent must be at least 50 characters';
                  }

                  if (intent.length > 512) {
                    return 'Intent must be less than 512 characters';
                  }

                  return true;
                },
              })}
            />
            <div className="absolute bottom-0 right-0 p-2 text-xs text-black">
              {intentCount}/512
            </div>
          </div>
        </div>
        <div>
          <Label htmlFor="include">What&apos;s included?</Label>
          <HelperText className="mb-2">
            What comes with your ticket? Include the main feature, plus any
            extras (e.g. food & drink).
          </HelperText>
          <div className="relative">
            <Textarea
              color="primary"
              id="include"
              rows={2}
              placeholder="1hr yoga flow, food & drink for the picnic & some new best buds."
              helperText={
                errors.intent?.include?.message ? (
                  <span className="text-failure ml-3">
                    {errors.intent?.include?.message}
                  </span>
                ) : null
              }
              {...register('intent.include', {
                onChange: (e) => setIncludeCount(e.target.value.length),
                validate: (exclude: string | undefined) => {
                  if (isManual) {
                    return true;
                  }

                  if ((exclude?.length ?? 0) > 265) {
                    return 'Should be less than 265 characters';
                  }

                  return true;
                },
              })}
            />
            <div className="absolute bottom-0 right-0 p-2 text-xs text-black">
              {includeCount}/265
            </div>
          </div>
        </div>

        <div>
          <Label htmlFor="bring">What to bring?</Label>
          <HelperText className="mb-2">
            Include things like dress code, potluck contributions, or
            activity-related things like towels & swimwear.
          </HelperText>
          <div className="relative">
            <Textarea
              color="primary"
              id="bring"
              rows={2}
              placeholder="Bring along a yoga mat, water bottle & clothes you can move in."
              helperText={
                errors.intent?.bring?.message ? (
                  <span className="text-failure ml-3">
                    {errors.intent?.bring?.message}
                  </span>
                ) : null
              }
              {...register('intent.bring', {
                onChange: (e) => setExcludeCount(e.target.value.length),
                validate: (bring: string | undefined) => {
                  if (isManual) {
                    return true;
                  }

                  if ((bring?.length ?? 0) > 265) {
                    return 'Should be less than 265 characters';
                  }

                  return true;
                },
              })}
            />
            <div className="absolute bottom-0 right-0 p-2 text-xs text-black">
              {bringCount}/265
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal show onClose={onClose} position="center">
      <Modal.Header>Create a new Experience</Modal.Header>
      <Modal.Body className="h-full">
        {hasAIFlag && (
          <FBTabs
            onActiveTabChange={(tab) => {
              setIsManual(tab === 1);
              reset();
            }}
          >
            <FBTabs.Item active title="Instant✨">
              {getAI()}
            </FBTabs.Item>
            <FBTabs.Item title="Manual">{getManual()}</FBTabs.Item>
          </FBTabs>
        )}
        {!hasAIFlag && getManual()}
      </Modal.Body>
      <Modal.Footer>
        <span className="w-full text-primary italic">
          {creatingExperience && quote}
        </span>
        <div className="flex justify-end items-center w-full">
          {error?.messages?.length && (
            <div className="text-failure mr-2">{error?.messages[0]}</div>
          )}
          <Button
            color="primary"
            onClick={handleSubmit(onSubmit)}
            icon={creatingExperience && <Spinner className="mr-2" />}
            disabled={creatingExperience || !isValid}
          >
            {creatingExperience ? 'Creating' : 'Create'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
